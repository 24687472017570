// @ts-strict-ignore
import { qpGetHeaderValue } from '@library/functions/qp-get-header-value/qp-get-header-value';
import { EQpImageThumbnailSize } from '@library/models/qp-image.models';
import {
  IQpFileUploadMetadata,
  IQpFileUploadArrayBufferOptions,
  IQpFileUploadArrayBuffer,
} from '@library/services/qp-file-upload/qp-file-upload.service.models';
import { HttpResponse, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface IQpFileUploadArrayBufferParams {
  thumbnail?: EQpImageThumbnailSize.S256;

  [param: string]: string | ReadonlyArray<string>;
}

@Injectable({
  providedIn: 'root',
})
export class QpFileUploadService {
  private static _toMetadata(body: Readonly<Record<string, string>>): IQpFileUploadMetadata {
    return {
      name: body['Filename'] ?? body['filename'],
      type: body['Content-Type'] ?? body['content-type'],
      size: body['Content-Length'] ?? body['content-length'],
    };
  }

  private static _toArrayBuffer(response: Readonly<HttpResponse<ArrayBuffer>>): IQpFileUploadArrayBuffer {
    return {
      type: qpGetHeaderValue(response.headers, 'Content-Type'),
      rawData: response.body,
    };
  }

  public constructor(private readonly _httpClient: HttpClient) {}

  public upload$<TResponse = Record<string, string>>(
    url: Readonly<string>,
    file: Readonly<NzUploadFile>
  ): Observable<HttpResponse<TResponse>> {
    const formData: FormData = new FormData();

    formData.append('file', file as unknown as File, file.name);

    return this._httpClient.post<TResponse>(url, formData, {
      observe: 'response',
    });
  }

  public loadMetadata$(url: Readonly<string>): Observable<IQpFileUploadMetadata> {
    return this._httpClient.get<Record<string, string>>(url).pipe(map(QpFileUploadService._toMetadata));
  }

  public loadArrayBuffer$(
    url: Readonly<string>,
    options?: Readonly<IQpFileUploadArrayBufferOptions>
  ): Observable<IQpFileUploadArrayBuffer> {
    const params: IQpFileUploadArrayBufferParams = {};
    const isThumbnail: boolean = options?.params?.isThumbnail ?? true;

    if (isThumbnail === true) {
      params.thumbnail = EQpImageThumbnailSize.S256;
    }

    return this._httpClient
      .get(url, {
        responseType: 'arraybuffer',
        observe: 'response',
        params: new HttpParams({
          fromObject: params,
        }),
      })
      .pipe(map(QpFileUploadService._toArrayBuffer));
  }
}
